.content-container > * {
  text-align: left;
  width: 100%;
}

img {
  width: 100%;
  max-width: 800px;
  display: block;
  margin: 0 auto;
}

video {
  width: 100%;
  max-width: 800px;
  display: block;
  margin: 0 auto;
}

pre {
  color: white;
}
