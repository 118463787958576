.settings-button {
  width: 30px;
  height: 30px;
}

.logo-image {
  height: 100px;
}

.nav-link {
  font-size: 20px;
  font-family: "Open Sans";
  color: white;
}

.active-link {
  text-decoration: underline;
}

.nav-link:hover {
  text-decoration: underline;
}

.navbar {
  background-color: #282c34;
}

.link-icons {
  width: 32px;
}
